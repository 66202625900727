import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/HallOfFame.css';

const HallOfFame = () => {
  const [scores, setScores] = useState({ facile: [], moyen: [], difficile: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScores = async () => {
      setLoading(true);
      try {
        const response = await axios.get('http://127.0.0.1:8000/api/scores');
        const fetchedScores = response.data;

        // Filtrer les scores par niveau de difficulté
        const facileScores = fetchedScores
          .filter(user => user.difficulty.toLowerCase() === 'easy')
          .sort((a, b) => b.score - a.score)
          .slice(0, 10);

        const moyenScores = fetchedScores
          .filter(user => user.difficulty.toLowerCase() === 'medium')
          .sort((a, b) => b.score - a.score)
          .slice(0, 10);

        const difficileScores = fetchedScores
          .filter(user => user.difficulty.toLowerCase() === 'hard')
          .sort((a, b) => b.score - a.score)
          .slice(0, 10);

        setScores({
          facile: facileScores,
          moyen: moyenScores,
          difficile: difficileScores
        });
      } catch (err) {
        setError(err);
        console.error("Erreur lors de la récupération des scores :", err);
      } finally {
        setLoading(false);
      }
    };
    fetchScores();
  }, []);

  if (loading) return <p>Chargement des scores...</p>;
  if (error) return <p>Erreur lors du chargement des scores : {error.message}</p>;

  return (
    <div>
      <h2>Hall of Fame</h2>
      
      {/* Tableau des scores Facile */}
      <div className="tableContainer">
        <h3>Facile</h3>
        <table border="1" className='tableHallOfFame'>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.facile.map((entry, index) => (
              <tr key={index}>
                <td>{entry.user}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Tableau des scores Moyen */}
      <div className="tableContainer">
        <h3>Moyen</h3>
        <table border="1" className='tableHallOfFame'>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.moyen.map((entry, index) => (
              <tr key={index}>
                <td>{entry.user}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Tableau des scores Difficile */}
      <div className="tableContainer">
        <h3>Difficile</h3>
        <table border="1" className='tableHallOfFame'>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.difficile.map((entry, index) => (
              <tr key={index}>
                <td>{entry.user}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HallOfFame;
